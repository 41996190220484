<template>
  <div class="container">
    {{ msg }}
  </div>
</template>

<script>
export default {
  name: "pending",
  components: {},
  data() {
    return {
      msg: "working",
    };
  },
};
</script>

<style></style>
